import { lazyRouteComponent } from "~/utilities/vite/lazyRouteComponent";
import { registry } from "~/utilities/vite/reactIslandRegistry";
import "./shared";

registry.registerIsland({
  name: "bunker/employees/components/EmployeeList",
  Component: lazyRouteComponent(
    () => import("~/bunker/employees/components/EmployeeList"),
    "EmployeeList",
  ),
});

registry.registerIsland({
  name: "bunker/wallet/Wallet",
  Component: lazyRouteComponent(
    () => import("~/bunker/wallet/Wallet"),
    "Wallet",
  ),
});

registry.registerIsland({
  name: "bunker/scheduling/components/AppointmentCard",
  Component: lazyRouteComponent(
    () => import("~/bunker/scheduling/components/AppointmentCard"),
    "AppointmentCard",
  ),
});

registry.registerIsland({
  name: "bunker/scheduling/components/AppointmentList",
  Component: lazyRouteComponent(
    () => import("~/bunker/scheduling/components/AppointmentList"),
    "AppointmentList",
  ),
});

registry.registerIsland({
  name: "bunker/referrals/ReferralNavItem",
  Component: lazyRouteComponent(
    () => import("~/bunker/referrals/ReferralNavItem"),
    "ReferralNavItem",
  ),
});

registry.registerIsland({
  name: "bunker/wisetack/components/WisetackCtaProvider",
  Component: lazyRouteComponent(
    () => import("~/bunker/wisetack/components/WisetackCtaProvider"),
    "WisetackCtaProvider",
  ),
});

registry.registerIsland({
  name: "bunker/wisetack/components/WisetackApplyButtonProvider",
  Component: lazyRouteComponent(
    () => import("~/bunker/wisetack/components/WisetackApplyButtonProvider"),
    "WisetackApplyButtonProvider",
  ),
});

// Testing if this will help git resolve merge conflicts better. Tried a suggestion I saw
// here https://chromium.googlesource.com/chromium/src/+/refs/heads/master/DEPS
// More lines
//
// Even more lines
//
// The end.
registry.registerIsland({
  name: "bunker/comms/CommsOptOutWrapper",
  Component: lazyRouteComponent(
    () => import("~/bunker/comms/CommsOptOutWrapper"),
    "CommsOptOutWrapper",
  ),
});

registry.registerIsland({
  name: "bunker/scheduleWeekDisplay",
  Component: lazyRouteComponent(
    () => import("~/bunker/scheduleWeekDisplay/ScheduleWeekDisplay"),
    "ScheduleWeekDisplay",
  ),
});

registry.registerIsland({
  name: "bunker/payments_react/clientHubJobberPayments/components/CheckoutContainer",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/bunker/payments_react/clientHubJobberPayments/components/CheckoutContainer"
      ),
    "CheckoutContainer",
  ),
});

registry.registerIsland({
  name: "bunker/imageUpload",
  Component: lazyRouteComponent(
    () => import("~/bunker/imageUpload"),
    "ImageUpload",
  ),
});

registry.registerIsland({
  name: "bunker/SCNetwork/RecommendationCardCTA",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/bunker/SCNetwork/components/RecommendationCardCTA/RecommendationCardCTA"
      ),
    "RecommendationCardCTA",
  ),
});

registry.registerIsland({
  name: "bunker/JobberNetwork/SCPropertySurvey",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/bunker/jobberNetwork/components/SCPropertySurvey/SCPropertySurvey.loader"
      ),
    "SCPropertySurveyLoader",
  ),
});

registry.registerIsland({
  name: "bunker/JobberNetwork/SCRecommendedServices",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/bunker/jobberNetwork/components/SCRecommendedServices/SCRecommendedServices.loader"
      ),
    "ThirdPanelSCRecommendedServices",
  ),
});

registry.registerIsland({
  name: "bunker/JobberNetwork/SCMaintenanceCalendar",
  Component: lazyRouteComponent(
    () =>
      import(
        "~/bunker/jobberNetwork/components/SCMaintenanceCalendar/SCMaintenanceCalendar"
      ),
    "ThirdPanelSCMaintenanceCalendar",
  ),
});

registry.registerIsland({
  name: "bunker/JobberNetwork/SCFindAPro",
  Component: lazyRouteComponent(
    () => import("~/bunker/jobberNetwork/components/SCFindAPro/SCFindAPro"),
    "SCFindAPro",
  ),
});

registry.registerIsland({
  name: "bunker/JobberNetwork/SCFindAProCard",
  Component: lazyRouteComponent(
    () =>
      import("~/bunker/jobberNetwork/components/SCFindAProCard/SCFindAProCard"),
    "ThirdPanelSCFindAProCard",
  ),
});

registry.defineElement();
